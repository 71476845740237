const TOP_SCROLL_POSITION = 50;
const SITE_HEADER_TOP_CLASS = "top";
const SITE_HEADER_VISIBLE_CLASS = "visible";
const SITE_HEADER_HIDDEN_CLASS = "hidden";
const SITE_HEADER_OPEN_CLASS = "open";
const SITE_HEADER_CLOSE_CALSS = "close";

const slidingComponents = Array.from(document.getElementsByClassName("slide"));
const navToggle = document.getElementById("nav-toggle");
const siteHeaderClasses = document.getElementById("site-header").classList;

let scrollState = 0;

function hideNavMenu() {
  navToggle.classList.remove("is-active");
  slidingComponents.forEach((component) => {
    component.classList.remove("open");
    component.classList.add("close");
  });
}

function showNavMenu() {
  navToggle.classList.add("is-active");
  slidingComponents.forEach((component) => {
    component.classList.add("open");
    component.classList.remove("close");
  });
}

function downAction() {
  if (!navToggle.classList.contains("is-active")) {
    siteHeaderClasses.remove(SITE_HEADER_VISIBLE_CLASS);
    siteHeaderClasses.add(SITE_HEADER_HIDDEN_CLASS);

    siteHeaderClasses.remove(SITE_HEADER_TOP_CLASS);
  }
}

function upAction() {
  siteHeaderClasses.remove(SITE_HEADER_HIDDEN_CLASS);
  siteHeaderClasses.add(SITE_HEADER_VISIBLE_CLASS);
}

function homeAction() {
  siteHeaderClasses.add(SITE_HEADER_TOP_CLASS);
}

function getScrollPosition() {
  return window.scrollY;
}

function scrollDetect(home, down, up) {
  const currentScrollPosition = getScrollPosition();

  if (currentScrollPosition < TOP_SCROLL_POSITION) {
    home();
  } else if (currentScrollPosition > scrollState) {
    down();
  } else {
    up();
  }

  scrollState = currentScrollPosition;
}

navToggle.addEventListener("click", (event) => {
  event.stopPropagation();

  if (slidingComponents[0].classList.contains("open")) {
    hideNavMenu();
  } else {
    showNavMenu();
  }
});

document.getElementById("content").addEventListener("click", (event) => {
  if (slidingComponents[0].classList.contains("open")) {
    event.stopPropagation();
    hideNavMenu();
  }
});

window.addEventListener("scroll", () => {
  scrollDetect(homeAction, downAction, upAction);
});
